import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, NavLink } from 'react-router-dom';
import './navbar.css';
import logo from './logo.jpg';
import removedLogo from './logo-removebg-preview.png';


const Navbar = () => {
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        let prevScrollPos = window.pageYOffset;
        const navbar = document.querySelector('.navbar');

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > prevScrollPos) {
                // Scrolling down
                navbar.classList.add('navbar--hidden');
            } else {
                // Scrolling up
                navbar.classList.remove('navbar--hidden');
            }

            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };


    return (
        <nav className={`navbar`}>
            <div className="navbar__container">
                <Link to="/Home" className="navbar__logo">
                    <img src={removedLogo} alt="Logo" className="navbar__logo-image" />
                </Link>
                <ul className="navbar__menu">
                    <li className="navbar__item">
                        <NavLink
                            exact
                            to="/Home"
                            className={`navbar__link ${activeLink === 'home' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('home')}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink
                            exact
                            to="/products"
                            className={`navbar__link ${activeLink === 'products' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('products')}
                        >
                            Products
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink
                            exact
                            to="/tables"
                            className={`navbar__link ${activeLink === 'tables' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('tables')}
                        >
                            Tables
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink
                            exact
                            to="/chairs"
                            className={`navbar__link ${activeLink === 'chairs' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('chairs')}
                        >
                            Chairs
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink
                            exact
                            to="/Contact"
                            className={`navbar__link ${activeLink === 'Contacts' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('Contacts')}
                        >
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import './tables.css';
import { productsDataTable } from './productsData';

import Footer from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUpRightDots, faTable } from '@fortawesome/free-solid-svg-icons';
import { FiChevronRight, FiFilter } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import firstimage from './firstimage.jpg';

import categoryButtonStandartTableImage from './standartTablesProduct.jpg';
import categoryButtonPremiumTableImage from './premiumTablesProduct.jpg';

import premiumExtensionButton from './premiumExtensionButton.jpg';
import premiumFixTopButton from './premiumFixTopButton.jpg';

import standartFixTopButton from './standartFixTopButton.jpg';
import standartExtension from './standartExtension.jpg';

const TablesPage = () => {
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedMainCategory, setSelectedMainCategory] = useState('All');
    const [selectedSubTableCategory, setSelectedSubTableCategory] = useState('');

    useEffect(() => {
        setSelectedCategory('All');
        setSelectedMainCategory('All');
    }, []);

    const handleProductClick = (productIndex) => {
        setSelectedProductIndex(productIndex);
        setSelectedImageIndex(0);
        setIsPopupOpen(true);
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.img.length) % selectedProduct.img.length);
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.img.length);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const handleMainCategoryClick = (category) => {
        setSelectedMainCategory(category);
        setSelectedSubTableCategory('');

        if (category === 'All') {
            setSelectedCategory('Masa');
            setSelectedProductIndex(null);
        } else {
            setSelectedCategory('Masa');
            setSelectedSubTableCategory('All');
            setSelectedProductIndex(null);
        }
    };

    const handleSubTableCategoryClick = (category) => {
        setSelectedSubTableCategory(category);

        if (category === 'All') {
            setSelectedCategory('Masa');
            setSelectedProductIndex(null);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedMainCategory('');
        setSelectedSubTableCategory('');

        if (category === 'Masa') {
            setSelectedProductIndex(null);
            setSelectedSubTableCategory('');
        }
    };

    const mainCategories = [
        { label: 'All', value: null, image: null },
        { label: 'Standard', value: 'Standard', image: categoryButtonStandartTableImage },
        { label: 'Premium', value: 'Premium', image: categoryButtonPremiumTableImage },
    ];

    let subTableCategories = [];

    if (selectedMainCategory === 'Standard') {
        subTableCategories = [
            { label: 'Standard Fix Top', value: 'Standard Fix Top', image: standartFixTopButton },
            { label: 'Standard Extension', value: 'Standard Extension', image: standartExtension },
        ];
    } else if (selectedMainCategory === 'Premium') {
        subTableCategories = [
            { label: 'Premium Fix Top', value: 'Premium Fix Top', image: premiumFixTopButton },
            { label: 'Premium Extension', value: 'Premium Extension', image: premiumExtensionButton },
        ];
    }

    const productsData =
        selectedCategory === 'All'
            ? productsDataTable
            : selectedSubTableCategory === 'All'
                ? productsDataTable.filter((product) => product.category === selectedCategory)
                : productsDataTable.filter(
                    (product) => product.category === selectedCategory && product.subTableCategory === selectedSubTableCategory
                );

    const [selectedOption, setSelectedOption] = useState(3);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const selectedProduct = selectedProductIndex !== null ? productsData[selectedProductIndex] : null;

    return (
        <div className="tables-products-page">
            <div
                className="tables-product-text"
                style={{
                    backgroundImage: `url(${firstimage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    alignItems: 'center',
                }}
            >
                <h2>Tables</h2>
            </div>
            <div className="tables-content-container">
                <div className="tables-sidebar">
                    <div className="tables-grid-option">
                        <p style={{ paddingRight: '10px' }}>Grid Option</p>
                        <button
                            className={`tables-grid-option-button${selectedOption === 3 ? 'active' : ''}`}
                            onClick={() => handleOptionChange(3)}
                        >
                            -
                        </button>
                        <button
                            className={`tables-grid-option-button${selectedOption === 4 ? 'active' : ''}`}
                            onClick={() => handleOptionChange(4)}
                        >
                            +
                        </button>
                    </div>
                    {
                        <div>
                            <div className="tables-mainCategory-filter">
                                {mainCategories.map((category) => (
                                    <button
                                        key={category.value}
                                        className={`tables-mainCategory-button ${selectedMainCategory === category.value ? 'active' : ''}`}
                                        onClick={() => handleMainCategoryClick(category.value)}
                                    >
                                        {category.label}
                                        <img src={category.image} />
                                    </button>
                                ))}
                            </div>

                            {selectedMainCategory && selectedMainCategory !== 'All' && (
                                <div className="tables-subTableCategory-filter">
                                    <p>Sub Category Filter:</p>
                                    {subTableCategories.map((category) => (
                                        <button
                                            key={category.value}
                                            className={`tables-subTableCategory-button ${selectedSubTableCategory === category.value ? 'active' : ''
                                                }`}
                                            onClick={() => handleSubTableCategoryClick(category.value)}
                                        >
                                            {category.label}
                                            <img src={category.image} alt={category.label} />
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div className={`tables-product-list grid-${selectedOption}`}>
                    {productsData.map((product, index) => (
                        <div className="tables-product-image" onClick={() => handleProductClick(index)} key={index}>
                            <img src={product.img[0]} alt={product.title} />
                            <h3 style={{ fontWeight: 'bold' }}>{product.title}</h3>
                            <p style={{ fontWeight: 'bolder' }}>{product.subCategory}</p>
                            <p>{product.subTableCategory}</p>
                        </div>
                    ))}
                </div>
            </div>
            {isPopupOpen && (
                <div className="tables-popup-overlay" onClick={closePopup}>
                    <div className="tables-popup" onClick={(event) => event.stopPropagation()}>
                        <div className="tables-popup-image">
                            <img src={selectedProduct.img[selectedImageIndex]} alt={selectedProduct.title} />
                        </div>
                        <div className="tables-popup-nav">
                            <button
                                className="tables-popup-nav-button"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handlePrevImage();
                                }}
                            >
                                ←
                            </button>
                            <button
                                className="tables-popup-nav-button"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleNextImage();
                                }}
                            >
                                →
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default TablesPage;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import ProductsPage from './products';
import Home from './Home';
import Navbar from './Navbar';
import Contact from './Contact';
import tables from './tables';
import chairs from './chairs';
import 'typeface-montserrat';


function App() {


  return (
    <Router>
      <div className="app">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={ProductsPage} />
          <Route path="/tables" component={tables} />
          <Route path="/chairs" component={chairs} />
          <Route path="/Contact" component={Contact} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;

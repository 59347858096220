import React, { useState, useEffect } from 'react';
import './Home.css';
import firstimage from "./firstimage.jpg";
import Sandalyeler6 from "../src/Sandalyeler kopyası/Sandalyeler6.jpg";

import StepperBackground from './StepperBack.jpeg';

import firstwhiteimage from './1.jpg';
import secondwhiteimage from './1kopyası.jpg';
import thirdwhiteimage from './Büyük1.jpg';
import fourthwhiteimage from './Büyük2.jpg';


import { productsDataTable } from './productsData';
import { productsDataChair } from './productsData';
import Footerm from './footer';
import Homefooter from './homefooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowTrendUp, faArrowUpRightDots, faChair, faMoneyBillTrendUp, faTable, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';

import video2 from './2.mp4';
import video3 from './3.mp4';
import video4 from './4.mp4';
import video7 from './7.mp4';
import video10 from './10.mp4';
import video12 from './12.mp4';
import video13 from './13.mp4';
import video17 from './17.mp4';
import video18 from './18.mp4';

import { useHistory } from 'react-router-dom';

function Home() {
    const history = useHistory(); // Create a history object

    const handleArrowChairClick = () => {
        history.push('/chairs'); // Navigate to the 'src/chairs.js' page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleArrowTableClick = () => {
        history.push('/tables'); // Navigate to the 'src/chairs.js' page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };




    const images = [
        { src: firstwhiteimage, alt: 'Product 1' },
        { src: secondwhiteimage, alt: 'Product 2' },
        { src: thirdwhiteimage, alt: 'Product 3' },
        { src: fourthwhiteimage, alt: 'Product 4' },
    ];
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const [selectedImageIndexPopUp, setSelectedImageIndexPopup] = useState(0);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, [images]);

    const [activeSection, setActiveSection] = useState('masalar');

    const handleSectionChange = (section, event) => {
        event.preventDefault();
        setActiveSection(section);
    };

    const handleProductClick = (index) => {
        setSelectedImageIndexPopup(index);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };


    return (
        <div className="App" style={{ backgroundColor: 'white' }}>
            <header className="Home-App-header">
                <div className="arrow-icon left" onClick={handlePrevImage}>
                    <FiChevronLeft size={24} color='black' />
                    <p1 style={{ color: 'black', fontSize: '12px' }}>{selectedImageIndex + 1} / 4 </p1>
                </div>
                <div className="Home-image-view__container">
                    <img src={images[selectedImageIndex].src} alt={images[selectedImageIndex].alt} className="Home-image-view__image" />

                </div>
                <div className="arrow-icon right" onClick={handleNextImage}>
                    <FiChevronRight size={24} color='black' />
                    <p1 style={{ color: 'black', fontSize: '12px' }}>{selectedImageIndex + 1} / 4 </p1>
                </div>

                <Homefooter />


                <div className="step-container"
                    style={{
                        backgroundImage: `url(${StepperBackground})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '5px',
                        width: '100%'
                    }}
                >

                    <p1 style={{ fontWeight: 'bold', paddingBottom: '20px' }}>OUR AIM IS</p1>

                    <div className="step" style={{ paddingBottom: '20px' }}>
                        <div className="step-number" style={{ color: 'white' }}>I</div>
                        <h2 className="step-title" style={{ color: 'white' }}>Quality</h2>
                    </div>

                    <div className="step">
                        <div className="step-number" style={{ color: 'white' }}>II</div>
                        <h2 className="step-title" style={{ color: 'white' }}>Sustainability</h2>
                    </div>

                    <div className="step">
                        <div className="step-number" style={{ color: 'white' }}>III</div>
                        <h2 className="step-title" style={{ color: 'white' }}>Product</h2>
                    </div>
                </div>



                <div class="wrapper-chair">
                    <div className="Home-Text-Panel-container-chair" style={{ backgroundImage: `url(${firstimage})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'black', display: 'flex', alignItems: 'center' }}>
                        <h1 style={{ color: 'white', fontSize: '24px' }}>Tables</h1>
                        <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'white' }} onClick={handleArrowTableClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'white', paddingLeft: '10px', fontSize: 'large' }} />
                        </button>
                    </div>
                </div>

                <div class="wrapper-chair">
                    <div className="Home-Text-Panel-container-chair" style={{ backgroundImage: `url(${Sandalyeler6})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'black', display: 'flex', alignItems: 'center' }}>
                        <h1 style={{ color: 'white', fontSize: '24px' }}>Chairs</h1>
                        <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'white' }} onClick={handleArrowChairClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'white', paddingLeft: '10px', fontSize: 'large' }} />
                        </button>
                    </div>
                </div>



                <div class="wrapper">
                    <div className="Home-Text-Panel-container" style={{ background: 'linear-gradient(to right,#333, gray)', color: 'black' }}>
                        <h2>commitment to excellence extends beyond</h2 >
                        <h1>
                            seamless experience for our customers, combining top-notch quality with exceptional customer service
                        </h1>
                        <h2>
                            true innovation
                        </h2>

                        <h1>
                            That's why we actively seek partnerships with other visionary companies and individuals, both locally and globally. By fostering these connections, we create a vibrant ecosystem that fuels creativity and allows us to deliver groundbreaking solutions that shape the future.
                            As we continue to evolve and adapt to the ever-changing landscape, we remain steadfast in our mission to bring the world's trends to global. With our passion for innovation, commitment to excellence, and unwavering dedication to customer satisfaction, we are confident in our ability to not only meet but exceed your expectations. Join us on this exciting journey as we shape the future of trends in World and beyond.
                        </h1>
                    </div>
                </div>
                <div class="wrapper">
                    <div className="Home-Text-Panel-container" style={{ background: 'white', color: 'black' }}>
                        <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>Trending Products</h1>
                    </div>


                    <div className="Home-section-buttons">
                        <button className={activeSection === 'masalar' ? 'active' : ''} onClick={(event) => handleSectionChange('masalar', event)}>
                            <FontAwesomeIcon icon={faTable} style={{ color: 'white', fontSize: 'small' }} />
                        </button>
                        <button className={activeSection === 'sandalyeler' ? 'active' : ''} onClick={(event) => handleSectionChange('sandalyeler', event)}>

                            <FontAwesomeIcon icon={faChair} style={{ color: 'white', fontSize: 'small' }} />
                        </button>
                    </div>
                </div>


                <div className="Home-section-content">
                    {activeSection === 'masalar' && (
                        <div className="Home-product-list">
                            {productsDataTable.slice(0, 6).map((product, tableindex) => (
                                <div key={product.id} className="Home-product-image">
                                    <img src={product.img[0]} alt={product.title} onClick={() => handleProductClick(tableindex)} />
                                </div>
                            ))}
                        </div>
                    )}
                    {activeSection === 'sandalyeler' && (
                        <div className="Home-product-list">
                            {productsDataChair.slice(0, 6).map((product, chairindex) => (
                                <div key={product.id} className="Home-product-image">
                                    <img src={product.img[0]} alt={product.title} onClick={() => handleProductClick(chairindex)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {
                    isPopupOpen && (
                        <div className="Home-popup-overlay" onClick={closePopup}>
                            <div className="Home-popup">
                                <img
                                    src={activeSection === 'masalar' ? productsDataTable[selectedImageIndexPopUp].img[0] : productsDataChair[selectedImageIndexPopUp].img[0]}
                                    alt={activeSection === 'masalar' ? productsDataTable[selectedImageIndexPopUp].title : productsDataChair[selectedImageIndex].title}
                                />
                            </div>
                        </div>
                    )
                }
                <div class="wrapper">
                    <div className="Home-Text-Panel-container" style={{ background: 'white', color: 'black' }}>
                        <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>Trending Videos</h1>


                    </div>
                    <div className="Home-section-buttons">
                        <button>
                            <FontAwesomeIcon icon={faVideo} style={{ color: 'white', paddingLeft: '10px', fontSize: 'small' }} />
                        </button>
                    </div>

                </div>

                <div className="parentContainer">
                    <div className="HorizontalScrollContainer">
                        <video controls>
                            <source src={video2} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video3} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video4} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video7} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video10} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video12} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video13} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video17} type="video/mp4" />
                        </video>

                        <video controls>
                            <source src={video18} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </header >
            <Footerm />
        </div >
    );
}

export default Home;

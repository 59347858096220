import React, { useState } from 'react';
import { productsDataChair } from './productsData';
import Footer from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair } from '@fortawesome/free-solid-svg-icons';
import './chairs.css';
import Sandalyeler6 from "../src/Sandalyeler kopyası/Sandalyeler6.jpg";

const ChairsPage = () => {
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);
    const [selectedColor, setSelectedColor] = useState(productsDataChair[0].color);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [gridOption, setGridOption] = useState(2);

    const handleProductClick = (productIndex, color) => {
        setSelectedProductIndex(productIndex);
        setSelectedColor(color);
        setSelectedImageIndex(0);
        setIsPopupOpen(true);
    };

    const handleColorOptionClick = (color) => {
        const product = productsData[selectedProductIndex];
        const productIndex = productsData.findIndex((p) => p.id === product.id && p.color === color);

        if (productIndex !== -1) {
            setSelectedProductIndex(productIndex);
            setSelectedColor(color);
            setSelectedImageIndex(0);
            setIsPopupOpen(true);
        }
    };


    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct?.img.length) % selectedProduct?.img.length);
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct?.img.length);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const productsData = productsDataChair;

    const selectedProduct = selectedProductIndex !== null ? productsData[selectedProductIndex] : null;

    const handleOptionChange = (option) => {
        setGridOption(option);
    };

    const [selectedOption, setSelectedOption] = useState(3);

    return (
        <div className="chairs-products-page">
            <div
                className="chairs-product-text"
                style={{
                    backgroundImage: `url(${Sandalyeler6})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    alignItems: 'center',
                }}
            >
                <h2>Chairs</h2>
            </div>
            <div className="chairs-content-container">
                <div className="chairs-sidebar">
                    <div className="chairs-grid-option">
                        <p style={{ paddingRight: '10px' }}>Grid Option</p>
                        <button
                            className={`chairs-grid-option-button${selectedOption === 3 ? ' active' : ''}`}
                            onClick={() => handleOptionChange(3)}
                        >
                            -
                        </button>
                        <button
                            className={`chairs-grid-option-button${selectedOption === 4 ? ' active' : ''}`}
                            onClick={() => handleOptionChange(4)}
                        >
                            +
                        </button>
                    </div>
                </div>

                <div className={`chairs-product-list grid-${gridOption}`}>
                    {productsData.map((product, index) => {
                        const sameProducts = productsData.filter((p) => p.id === product.id && p.color !== product.color);

                        return (
                            <div className="chairs-product-image" key={index}>
                                <img
                                    src={product.img[0]}
                                    alt={product.title}
                                    onClick={() => handleProductClick(index, product.color)}
                                />
                                <h3 style={{ fontWeight: 'bold' }}>{product.title}</h3>
                                <p style={{ fontSize: '10px' }}>{product.color}</p>
                                {sameProducts.length > 0 && selectedProduct && (
                                    <div>
                                        <p1 style={{ fontSize: '10px', paddingRight: '10px' }}>Other Colors:</p1>
                                        {sameProducts.map((p, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleColorOptionClick(p.color)}
                                                style={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    borderRadius: '10px',
                                                    fontSize: '8px',
                                                    borderColor: 'black',
                                                }}
                                                className={selectedColor === p.color ? 'selected' : ''}
                                            >
                                                {p.color}
                                            </button>
                                        ))}
                                    </div>
                                )}
                                <p>{product.subCategory}</p>
                            </div>
                        );
                    })}
                </div>
            </div>

            {isPopupOpen && (
                <div className="chairs-popup-overlay" onClick={closePopup}>
                    <div className="chairs-popup" onClick={(event) => event.stopPropagation()}>
                        <div className="chairs-popup-image">
                            <img src={selectedProduct?.img[selectedImageIndex]} alt={selectedProduct?.title} />
                        </div>
                        <div className="chairs-popup-nav">
                            <button className="chairs-popup-nav-button" onClick={(event) => { event.stopPropagation(); handlePrevImage(); }}>
                                ←
                            </button>
                            <button className="chairs-popup-nav-button" onClick={(event) => { event.stopPropagation(); handleNextImage(); }}>
                                →
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default ChairsPage;
